import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Badge, Modal } from 'react-bootstrap';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class MaterialformDeadlineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deadline: {},
      changed: false
    };
  }

  componentDidMount() {
    let deadline = {...this.props.data};
    deadline.deadline = Date.parse(deadline.deadline);
    this.setState({deadline});
  }

  onChangeDatePicker = (date, target) => {
    let deadline = this.state.deadline;
    deadline.deadline = date;
    this.setState({deadline, changed: true});
  }

  saveDeadline = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    let payload = this.state.deadline;
    payload.deadline = new Date(payload.deadline.getTime() - (payload.deadline.getTimezoneOffset() * 60000));

    if(payload.id){
      api.materialFormDeadlineEdit(payload.id, payload).then((materialFormDeadline) => {
        let deadline = this.props.data;
        this.props.changeCallback("materialform", materialFormDeadline);
        this.props.onHide();
      });
    } else {
      api.materialFormDeadlineCreate(payload).then((materialFormDeadline) => {
        let deadline = this.props.data;
        this.props.changeCallback("materialform", materialFormDeadline);
        this.props.onHide();
      });
    }
  }

  deleteDeadline = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    let payload = this.state.deadline;
    api.materialFormDeadlineDelete(payload.id).then((materialFormDeadline) => {
      this.props.changeCallback("materialform", null, payload.id);
      this.props.onHide();
    });
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{this.state.deadline.id ? "Muokkaa aikataulua" : "Aseta aikataulu"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{minHeight:"400px"}}>
          <p>Materiaalivalintalomake täytettävä viimeistään:</p>
          <DatePicker
            name="deadline"
            selected={this.state.deadline.deadline}
            onChange={(date) => this.onChangeDatePicker(date)}
            className="datepicker-input"
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeSelect
            timeFormat="HH:mm"
          />
          <div>
            <label style={{paddingRight:"15px"}}>Muistutus lähetetty:</label>
            <input id="sent" disabled={true} type="checkbox" checked={this.state.deadline.sent}/>
            <p style={{paddingTop:"30px"}}>Muistutus lähetetään viikkoa ennen deadlinea.</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {this.state.deadline.id && <Button variant="secondary" onClick={this.deleteDeadline}>Poista</Button>}
        <Button variant="primary" disabled={!this.state.changed} onClick={this.saveDeadline}>Tallenna</Button>
      </Modal.Footer>
    </Modal>;
  }
}
export default MaterialformDeadlineModal;
