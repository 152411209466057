import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button, Row, Modal, Badge } from 'react-bootstrap';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import CustomSelect from '../../multiview/Select/CustomSelect.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaCore from 'avainia-core-api';

class CondominiumUsersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userToEdit: this.props.userToEdit,
      loading: false,
      error: false,
      toggleNewPasswordField: false,
      showNewPasswordConfirm: false,
    };
  }

  validateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return (true);
    }
    alert(I18n.t('user.invalid-email-address'));
    return (false);
  }

  handleUserSelectChange = (name, value) => {
    const { userToEdit } = this.state;
    userToEdit[name] = value.value;
    this.setState({ userToEdit });
  }

  onUserFormChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;

    if(e.target.type == "checkbox"){
      value = !this.state.userToEdit[name];
    }

    const { userToEdit } = this.state;
    userToEdit[name] = value;

    this.setState({ userToEdit });
  }

  addCallback = (editedUser) => {
    if (this.state.loading) { return; }
    const payload = {
      name: editedUser.name,
      email: editedUser.email,
      role: editedUser.role,
      phone: editedUser.phone,
      street_address: editedUser.street_address,
      postnumber: editedUser.postnumber,
      city: editedUser.city,
      enabled: editedUser.enabled,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumUserCreate(this.props.condominiumId, payload).then((user) => {
      if (user.error) { this.setState({ userError: user.error }); }
        else {
          this.props.addUserCallback(user, editedUser);
        }
    });
  }

  save = (e) => {
    const editedUser = this.state.userToEdit;
    if (editedUser.new) {
      if (editedUser.name && editedUser.email && editedUser.role) {
        if (this.validateEmail(editedUser.email)) {
          this.addCallback(editedUser);
        }
      } else {
        alert(I18n.t('user.invalid-credentials'));
      }
    } else {
      this.props.editCallback(editedUser);
    }
  }

  setNewPassword = () => {
    if (!this.state.userToEdit.newPassword) { return alert(I18n.t('views.companies.new-password-empty-warning')) }
    if (this.state.loading) { return; }
    this.setState({ loading: true }, () => {
      const localToken = LocalStorageService.getToken();

      const payload = {
        userId: this.state.userToEdit.id,
        password: this.state.userToEdit.newPassword,
      };

      const api = new AvainiaCore(localToken);
      api.userPasswordManualChange(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        this.toggleNewPasswordField();
        this.setState({ loading: false, showNewPasswordConfirm: true })
      });
    });
  }

  toggleNewPasswordField = () => {
    const user = this.state.userToEdit;
    user.newPassword = '';

    this.setState(prevState => ({
      toggleNewPasswordField: !prevState.toggleNewPasswordField,
      userToEdit: user,
      showNewPasswordConfirm: false
    }));
  }

  remove = () => {
    this.props.removeCallback(this.props.userToEdit.id);
    this.props.onHide();
  }

  render() {
    console.log(this.state.userToEdit);
    const roleOptions = this.state.userToEdit.role ? { label: I18n.t(`views.condominiums.${this.state.userToEdit.role}`), value: this.state.userToEdit.role } : null;
    const UserRoles = [
      {
        label: I18n.t('views.condominiums.owner'),
        value: 'owner',
      },
      {
        label: I18n.t('views.condominiums.tenant'),
        value: 'tenant',
      },
    ];

    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {(!this.state.userToEdit.new && !this.state.userError) && I18n.t('views.condominiums.users.edit-user')}
          {(this.state.userToEdit.new || this.state.userError) && I18n.t('views.condominiums.users.add-user')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tinyform">
          {(this.state.userToEdit.new || this.state.userError) &&
            <Form.Group as={Row}>
              <CustomSelect
                name="role"
                placeholder={I18n.t('views.condominiums.users.role')}
                handleChange={this.handleUserSelectChange}
                selectOptions={UserRoles}
                value={roleOptions}
              />
            </Form.Group>
          }
          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('views.condominiums.users.name')} type="text" onChange={this.onUserFormChange} name="name" value={this.state.userToEdit.name} />
          </Form.Group>
          {(this.state.userToEdit.new || this.state.userError) &&
            <Form.Group as={Row}>
              <Form.Control placeholder={I18n.t('general.email')} type="text" onChange={this.onUserFormChange} name="email" value={this.state.userToEdit.email} />
              {this.state.userError && <div><Badge variant="danger">{I18n.t('views.condominiums.users.email-in-use')}</Badge></div>}
            </Form.Group>
          }
          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('general.phone')} type="text" onChange={this.onUserFormChange} name="phone" value={this.state.userToEdit.phone} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('general.street_address')} type="text" onChange={this.onUserFormChange} name="street_address" value={this.state.userToEdit.street_address} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('general.postnumber')} type="text" onChange={this.onUserFormChange} name="postnumber" value={this.state.userToEdit.postnumber} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('general.city')} type="text" onChange={this.onUserFormChange} name="city" value={this.state.userToEdit.city} />
          </Form.Group>

          {!this.state.userToEdit.new &&
            <>
            <Form.Group as={Row}>
              <label style={{marginRight:"12px"}}>{I18n.t('views.condominiums.users.enabledfield')}</label>
              <Form.Check name="enabled" onClick={this.onUserFormChange} checked={this.state.userToEdit.enabled} />
            </Form.Group>
            <Form.Group as={Row}>
              <p>{I18n.t('views.condominiums.users.enableInstructions')}</p>
            </Form.Group>
            </>
          }
          <Form.Group as={Row}>
              {this.state.toggleNewPasswordField &&
                <div className="new-password">
                  <Form.Control
                    placeholder={I18n.t('views.passwordset.new')}
                    type="password"
                    onChange={this.onUserFormChange}
                    name="newPassword"
                    value={this.state.userToEdit.newPassword}
                  />
                </div>
              }
              {this.state.toggleNewPasswordField &&
                <Button variant="info" onClick={this.setNewPassword}>{I18n.t('views.passwordset.title')}</Button>
              }

              {this.state.showNewPasswordConfirm &&
                  <p>{I18n.t('views.passwordset.message-general-success')}</p>
              }
            </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="user-buttons">
          <Button variant="info" onClick={this.toggleNewPasswordField}> {I18n.t('views.login.please-enter-new-password')} </Button>
          {(!this.state.userToEdit.new && !this.state.userError) && <Button variant="danger" onClick={this.remove}>{I18n.t('general.delete')}</Button>}
          <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
          <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.cancel')}</Button>
        </div>
      </Modal.Footer>
    </Modal>;
  }
}

export default CondominiumUsersModal;
