import React, { Component } from 'react';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Button, InputGroup, Form, Row, Col } from 'react-bootstrap';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import CondominiumUsersModal from './CondominiumUsersModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import CondominiumExistingUsersModal from './CondominiumExistingUsersModal.js';
import moment from 'moment';

const Modals = {
  editUser: 1,
};

class CondominiumUsersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      name: '',
      email: '',
      role: '',
      usersFiltered: [],
      userToEdit: null,
      search: '',
      modal: 0,
      addExistingUserModal: false,
      loading: false,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.condominium;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.getCondominiumUsers(id).then((users) => {
      const userArray = [];

      if (users.Tenants.length > 0) {
        users.Tenants.forEach((tenant) => {
          tenant.role = 'Tenant';
          userArray.push(tenant);
        });
      }

      if (users.Owners.length > 0) {
        users.Owners.forEach((owner) => {
          owner.role = 'Owner';
          userArray.push(owner);
        });
      }

      if (users.error) { return this.setState({ error: users.error }); }
      this.setState({ users: userArray, usersFiltered: userArray, loading: false });
    });
  }

  search = (e) => {
    const { users } = this.state;
    const term = e.target.value;
    const searchable = ['name', 'email', 'type'];

    const searchResults = users.filter((obj) => Object.keys(obj).filter((key) => searchable.includes(key))
      .some((key) => {
        return obj[key]?.toLowerCase().includes(term);
      }));

    this.setState({ usersFiltered: (searchResults || []) });
  }

  closeModal = (e) => {
    this.setState({ modal: 0, userToEdit: null });
  }

  addCallback = (editedUser) => {
    if (this.state.loading) { return; }
    const payload = {
      name: editedUser.name,
      email: editedUser.email,
      role: editedUser.role,
      phone: editedUser.phone,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumUserCreate(this.props.condominium.id, payload).then((user) => {
      if (user.error) { return this.setState({ error: user.error, modal: Modals.editUser }); }
      const { users } = this.state;
      user.role = editedUser.role;
      users.push(user);
      this.setState({ users, loading: false });
    });
  }

  editCallback = (editedUser) => {
    const payload = {
      name: editedUser.name,
      phone: editedUser.phone,
      enabled: editedUser.enabled,
      street_address: editedUser.street_address,
      postnumber: editedUser.postnumber,
      city: editedUser.city,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumUserEdit(this.props.condominium.id, editedUser.id, payload).then((user) => {
      if (user.error) { return this.setState({ error: user.error }); }
      window.location.reload(); // TODO: Improve
    });
  }

  addUserCallback = (user, editedUser) => {
    const { users } = this.state;
    user.role = editedUser.role;
    users.push(user);
    this.setState({ users, loading: false, modal: false });
  }

  removeCallback = (userId) => {
    if (!window.confirm(I18n.t('user.confirm-delete'))) { return; }
    if (this.state.loading) { return; }
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumUserDelete(this.props.condominium.id, userId).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }
      window.location.reload(); // TODO: Improve
    });
  }

  addExistingUserCallback = (user, roleLabel) => {
    const { users } = this.state;
    user.role = roleLabel;
    users.push(user);
    this.setState({ users, loading: false, addExistingUserModal: false });
  }

  handleUserClick = (e) => {
    const row = e.target.parentElement;
    const dataCell = row.children;

    const id = dataCell[0].innerText;
    const name = dataCell[1].innerText;
    const email = dataCell[2].innerText;

    for (let i = 0; i < this.state.users.length; i++) {
      const user = this.state.users[i];

      if (id == user.id) {
        this.setState({ userToEdit: user, modal: Modals.editUser });
      }
    }
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }
    return <div className="App-container">
      <Row className="align-items-center mb-5">
        <Col xs={3}>
          <h1 className="App-pageTitle mb-0">{I18n.t('views.condominiums.users.title')}</h1>
        </Col>
        <Col xs={4}>
          <InputGroup>
            <Form.Control
              placeholder={I18n.t('views.condominiums.users.search')}
              name="Search"
              type="text"
              onChange={this.search}
            />
          </InputGroup>
        </Col>
        <Col xs={2}>
          <div style={{ display: 'flex' }}>
            <Button
              block
              variant="primary"
              style={{ margin: '0px' }}
              onClick={() => { this.setState({ modal: Modals.editUser, userToEdit: { id: Date.now(), new: true } }); }}>
              {I18n.t('views.condominiums.users.add')}
            </Button>
          </div>
        </Col>
        <Col xs={3}>
          <div style={{ display: 'flex' }}>
            <Button
              block
              variant="primary"
              style={{ margin: '0px' }}
              onClick={() => { this.setState({ addExistingUserModal: true }); }}>
              {I18n.t('views.condominiums.users.add-existing')}
            </Button>
          </div>
        </Col>
      </Row>

      <AvainiaTable
        keyField='email'
        data={this.state.usersFiltered}
        rowClickHandler={this.handleUserClick}
        defaultSorted={[{ dataField: 'name'}]}
        columns={[
          { dataField: 'id', text: '#', headerStyle: { width: '80px' } },
          { dataField: 'name', sort: true, text: I18n.t('views.condominiums.users.name') },
          { dataField: 'email', text: I18n.t('views.condominiums.users.email') },
          { dataField: 'role', text: I18n.t('views.condominiums.users.role') },
          { dataField: 'id', text: I18n.t('views.condominiums.users.apartments'), formatter: (cell, row) => {
            let s = '';
            
            if(row.apartmentRoles){
              row.apartmentRoles.map((x) => {
                if(s != ''){s += ", ";}
                s += x.apartment;
              });
            }
            
            return s;
          }},
          { dataField: 'last_login_at', text: I18n.t('views.condominiums.users.lastLogin'), formatter: (cell, row) => {
            return cell ? moment(cell).format('DD.MM.YYYY HH:mm') : '-';
          }},
          { dataField: 'enabled', text: I18n.t('views.condominiums.users.enabled'), formatter: (cell, row) => {
            return cell ? '' : I18n.t('views.condominiums.users.notaccepted')
          }},
        ]}
      />

      {this.state.modal === Modals.editUser &&
        <CondominiumUsersModal
          addCallback={this.addCallback}
          editCallback={this.editCallback}
          removeCallback={this.removeCallback}
          userToEdit={this.state.userToEdit}
          onHide={this.closeModal}
          condominiumId={this.props.condominium.id}
          addUserCallback={this.addUserCallback}
        />
      }
      {this.state.addExistingUserModal &&
        <CondominiumExistingUsersModal
          condominiumId={this.props.condominium.id}
          condominiumUsers={this.state.users}
          addExistingUserCallback={this.addExistingUserCallback}
          onHide={() => this.setState({ addExistingUserModal: false })}
        />
      }
    </div>;
  }
}

export default CondominiumUsersTab;
